<template>

  <v-sheet elevation="0"
           v-if="loader.detail"
           :color="`grey darken-2 `"
           class="pa-0"
  >
    <v-skeleton-loader
      class="mx-auto"
      width="100%"
      type="card"
    ></v-skeleton-loader>
  </v-sheet>
  <v-card v-else>
    <v-img height="300" class=" align-end"
           :src="oneshot.cover_src"
           cover
           gradient="to bottom, rgba(255,255,255,.0), rgba(255,255,255,.9)"
    >
      <div class="d-flex flex-column fill-height  text-white">
        <v-card-subtitle v-if="oneshot.contest" color="deep-purple darken-3" class="mb-0 pb-0">
          <router-link :to="'/contest/'+oneshot.contest.id"> Concours :
            {{ oneshot.contest.cmstext.title }}
          </router-link>
        </v-card-subtitle>
        <v-card-title>
          {{ oneshot.title }}
          <v-spacer></v-spacer>
          <template  v-if="participantid" >
<!--            :disabled="!oneshot.status"-->
            <v-btn color="primary" small :to="`/scan/one-shot/`+oneshot.chapter.id">
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
              Lire le One-Shot
            </v-btn>
            <v-btn v-if="user_id == oneshot.user_id" color="warning" class="ml-3"
                   small
                   :to="'/contest/participant/'+oneshot.id">
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
              Editer
            </v-btn>
          </template>
          <template v-else-if="user_id == oneshot.user_id"  >
            <v-btn color="warning" class="ml-3" small
                   :to="'/chapter/form/'+oneshot.id">
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
              Editer
            </v-btn>
          </template>

          <v-btn v-if="!oneshot.contest" color="primary" small :to="`/scan/one-shot/`+oneshot.id">
            Lire le One-Shot
          </v-btn>

        </v-card-title>
        <v-card-subtitle color="deep-purple darken-3" class="mb-0 pb-0">
          <v-chip small color="whitesmook"
                  v-for="gender in oneshot.genders"
                  :key="gender.id"
          >
            {{ gender.name['fr'] }}
          </v-chip>
        </v-card-subtitle>
        <v-card-text class="py-0">
          <h1>
            {{ oneshot.name }}
          </h1>
        </v-card-text>

        <v-card-text class="py-0">
          <template v-for="author in oneshot.authors">
            <b>{{ author.pseudo }}</b>
          </template>

          <template v-if="oneshot.user_id">
            <div
              :to="'/profile/'+oneshot.user_id+''" color="none"
              class="d-flex flex-no-wrap justify-start">
              <user-avatar :user="oneshot.user" :size="30"></user-avatar>
              <v-card-title class="title-inline ma-1 pa-0 text-center">
                <b>{{ oneshot.user.username }}</b>
              </v-card-title>
            </div>
          </template>
        </v-card-text>
        <v-card-actions class="text-center py-0">
                  <span class="text-center">
                  <v-rating readonly
                            :value="parseFloat(oneshot.rate)"
                            hover
                            :size="21" dense
                            half-increments density="compact"
                            active-color="primary"
                  />
                 <small>{{ oneshot.rate }}/5 | {{ oneshot.nbvote }} Votes</small>

                  </span>
          <v-spacer></v-spacer>
        </v-card-actions>
      </div>
    </v-img>
    <!--    <v-card-title color="deep-purple darken-3">Synopsis</v-card-title>
        <v-card-text>
          {{ oneshot.synopsis }}
        </v-card-text>-->

    <v-row>
      <v-col cols="12" lg="7" md="7">
        <h3>Synopsis ...</h3>
        <read-more v-if="oneshot.synopsis"
          :content="oneshot.synopsis" :max-length="120"></read-more>
      </v-col>
      <v-col cols="12" lg="5" md="5">
        <comicbook-statistic-component
          :user_id="user_id"
          :comicbook="oneshot"
        ></comicbook-statistic-component>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="7">
        <v-tabs class=" position-sticky font-secondary"
                v-model="tab" center-active
                align-tabs="center"
                grow
        >
          <v-tab
            key="illustrations"
          >
            Extrait
          </v-tab>
          <v-tab
            key="oneshot"
          >
            Actualites
          </v-tab>
        </v-tabs>

        <template>

          <v-tabs-items v-model="tab">
            <v-tab-item
              key="illustrations"
            >
              <v-row no-gutters>
                <v-col v-for="(item, $index) in scanpages"
                       cols="4"
                       :key="'wt-'+item.id">
                  <v-img
                    aspect-ratio="1"
                    class="ma-auto"
                    :src="item.src"
                    :lazy-src="require('@/assets/images/holder.jpg')"
                  ></v-img>
                </v-col>
              </v-row>
              <template  v-if="participantid" >
                <!--            :disabled="!oneshot.status"-->
                <v-btn color="primary" large :to="`/scan/one-shot/`+oneshot.chapter.id">
                  <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  Lire le One-Shot
                </v-btn>
                <v-btn v-if="user_id == oneshot.user_id" color="warning" class="ml-3"
                       small
                       :to="'/contest/participant/'+oneshot.id">
                  <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  Editer
                </v-btn>
              </template>
              <template v-else-if="user_id == oneshot.user_id"  >
                <v-btn color="warning" class="ml-3" small
                       :to="'/chapter/form/'+oneshot.id">
                  <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  Editer
                </v-btn>
              </template>
            </v-tab-item>
            <v-tab-item
              :key="'series'"
            >
              <comicbook-creator-activity-component
                v-if="oneshot.id"
                :user="user"
                :comicbook="oneshot"
                :parent_id="oneshot.post_id"
              ></comicbook-creator-activity-component>
            </v-tab-item>

          </v-tabs-items>
        </template>
      </v-col>
      <v-col cols="12" md="5">
        <comicbook-comment-resume-component
          :comicbook="oneshot"
          :user="user"
          :url="`comicbook?cb_id=${oneshot.id}`"
        ></comicbook-comment-resume-component>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHeart,
  mdiHeartOutline,
  mdiCommentOutline,
  mdiExclamation,
  mdiInformationOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiNote,
  mdiBookOpenPageVariantOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import {useRouter} from '@/utils'
import ToggleFavoriteComponent from '@/components/ToggleFavoriteComponent'
import ProfileCreatorComponent from "@/components/ProfileCreatorComponent.vue";
import CreatorSeriesComponent from "@/components/creator/CreatorSeriesComponent.vue";
import ProfilePostComponent from "@/components/ProfilePostComponent.vue";
import ComicChapterComponent from "@/components/comicbook/ComicChapterComponent.vue";
import PostReplyFormComponent from "@/components/PostReplyFormComponent.vue";
import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import ComicbookFollowToggleComponent
  from "@/components/comicbook/ComicbookFollowToggleComponent.vue";
import CommentComponent from "@/components/CommentComponent.vue";
import CommentItemComponent from "@/components/CommentItemComponent.vue";
import ComicbookCommentResumeComponent
  from "@/components/comicbook/ComicbookCommentResumeComponent.vue";
import PostComponent from "@/components/PostComponent.vue";
import PostKolaItemsComponent from "@/components/PostKolaItemsComponent.vue";
import ComicbookCreatorActivityComponent
  from "@/components/comicbook/ComicbookCreatorActivityComponent.vue";
import ComicbookStatisticComponent from "@/components/comicbook/ComicbookStatisticComponent.vue";
import ReadMore from "@/components/ReadMore.vue";
import UsernameComponent from "@/components/UsernameComponent.vue";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  components: {
    UserAvatar,
    UsernameComponent,
    ReadMore,
    ComicbookStatisticComponent,
    ComicbookCreatorActivityComponent,
    PostKolaItemsComponent,
    PostComponent,
    ComicbookCommentResumeComponent,
    CommentItemComponent,
    CommentComponent,
    ComicbookFollowToggleComponent,
    FollowToggleComponent,
    PostReplyFormComponent,
    ComicChapterComponent,
    ProfilePostComponent,
    CreatorSeriesComponent,
    ProfileCreatorComponent,
    ToggleFavoriteComponent
  },
  setup(props, {emit}) {
    const $vuetify = getVuetify()

    const {router} = useRouter()
    const route = router.currentRoute
    const {id, participantid} = route.params
    const oneshot = ref({contest: {}})
    const loader = ref({
      detail: true,
      loading: true,
    })

    console.log(id, participantid)
    const scanpages = ref([])
    const tab = ref(0)

    const user_id = localStorage.getItem('user_id')
    const user = ref(Drequest.getUser())
    let url = ''
    if (user_id) {
      url = `&user_id=${user_id}`
    }
    loader.value.detail = true
    if (participantid) {

      Drequest.api('detail.participant?jsonmodel=detail&id=' + participantid)
        .get(response => {
          console.log(response)
          oneshot.value = response.participant

          scanpages.value = response.participant.pages
          loader.value.detail = false
        })
    } else {
      Drequest.api(`detail.chapter?id=${id}` + url)
        .get(response => {
          console.log(response)
          loader.value.detail = false
          oneshot.value = response.chapter
          emit('setTitle', oneshot.value.title, `/one-shot/${oneshot.value.slug}/` + oneshot.value.id)
        })

      const sub_id = localStorage.getItem('subscription_id')
      if (sub_id) {
        url += `&subscription_id=${sub_id}`
      }

      loader.value.scanpages = true
      Drequest.api(`lazyloading.scanpage?dfilters=on&next=1&per_page=3&drand=1&chapter_id:eq=${id}`)
        .get(response => {
          console.log(response)
          loader.value.scanpages = false
          scanpages.value = response.listEntity

          // currentpage.value = 1
        })

    }

    const onSubscribed = (response) => {
      console.log(response)
    }

    const slider_height = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 200
      }
      if ($vuetify.breakpoint.xs) {
        return 100
      }

      return 300
    })

    return {
      onSubscribed,

      loader,
      participantid,
      user_id,
      user,
      oneshot,
      scanpages,
      slider_height,
      tab,

      icons: {
        mdiExclamation,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiInformationOutline,
        mdiCommentOutline,
        mdiHeartOutline,
        mdiHeart,
        mdiNote,
        mdiBookOpenPageVariantOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
